import { LogsDefinitions } from '../api/types';

/**
 * Finds a filter in the groups
 *
 * @param groups - groups of filters
 * @param predicate - the filters predicate
 */
export function findFilterInGroup(
  groups: LogsDefinitions.FiltersGroup[] | undefined | null,
  predicate: (filter: LogsDefinitions.Filter, group: LogsDefinitions.FiltersGroup) => boolean
): LogsDefinitions.Filter | null {
  if (!groups) {
    return null;
  }

  for (const group of groups) {
    if (!group.filters) {
      continue;
    }

    for (const attribute of group.filters) {
      if (predicate(attribute, group)) {
        return attribute;
      }
    }
  }

  return null;
}

/**
 * Iterates on the filters of the groups
 *
 * @param groups - groups of filters
 * @param attributeCb - the attribute callback
 */
export function iterateFilterGroups(
  groups: LogsDefinitions.FiltersGroup[] | null,
  filterCb: (attr: LogsDefinitions.Filter) => void
): void {
  groups?.forEach((group) => {
    group?.filters?.forEach((filter) => {
      if (filter) {
        filterCb(filter);
      }
    });
  });
}

/**
 * Iterates the filter groups to find which filters to invoke the updateOn with.
 * NOTE: the updateOn mechanism is the same as the basic attribute form mechanism:
 * the filter holds the attributes, but the updateOn aliases should reference the attributes
 * not the filters
 *
 * @param filterGroups - the filter groups
 * @param filter - the filter for whom to update *for*
 * @param actionCb - Function to execute on the attributes that react to the current attribute change (take an attribute alias in parameter)
 * @param managedAttributeAliases - Array of attribute aliases already managed (prevent infinite loop)
 */
export function filterUpdateOn(
  filterGroups: LogsDefinitions.FiltersGroup[],
  filter: LogsDefinitions.Filter,
  actionCb: (filter: LogsDefinitions.Filter) => void,
  managedAttributeAliases: string[] = []
): void {
  if (!filter.attribute?.alias || managedAttributeAliases.includes(filter.attribute.alias)) {
    return;
  }

  managedAttributeAliases.push(filter.attribute.alias);
  if (!filterGroups) {
    return;
  }

  iterateFilterGroups(filterGroups, (iterateFilter) => {
    // Action on attribute that update on current alias and not already managed (+ call recursively)
    if (
      iterateFilter.attribute?.updateOn &&
      iterateFilter.attribute.updateOn.length > 0 &&
      iterateFilter.attribute.updateOn.includes(filter.attribute!.alias) &&
      !managedAttributeAliases.includes(iterateFilter.alias)
    ) {
      // Launch callback for attribute alias found
      actionCb(iterateFilter);

      // Call updateOn for current attribute change
      filterUpdateOn(filterGroups, iterateFilter, actionCb, managedAttributeAliases);
    }
  });
}
