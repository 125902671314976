import Vue from 'vue';
import { Store } from 'vuex';

import store from '../../../core/store';
import { USER_LOGOUT } from '../../../core/store/modules/coreModule';
import { Response } from '../../../core/types/http';
import { Form } from '../../common/api/attributes';
import actionsApi, { Actions } from '../api/actions';
import { CreationActions } from '../constants/index';
import { GetSendActionAlias } from '../helpers/actionDefinitionHelpers';
import {
  CLEAR_SEND_ACTION_CONF,
  CLEAR_SEND_ACTION_FORM,
  NAMESPACE as CREATION_NAMESPACE,
  RESET_STATE,
  SET_SEND_ACTION_CONF,
  SET_SEND_ACTION_FORM,
} from '../store/modules/actionDefinitions';
import { IActionDefinitionsService } from './types';

export class ActionDefinitionsService implements IActionDefinitionsService {
  /**
   * @param store - vuex store
   */
  constructor(private readonly store: Store<unknown>) {
    store.subscribeAction((action) => {
      if (action.type === USER_LOGOUT) {
        this.clearCache();
      }
    });
  }

  /**
   * @param action - internal send action
   * @returns the stored action configuration
   */
  private _getStoredActionConfiguration(action: CreationActions): Actions.Action {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${CREATION_NAMESPACE}/getActionConf`](action);
  }

  /**
   * Retrieves the existing configuration or fetches it from the api
   *
   * @param action - the action whose configuration to retrieve
   */
  async retrieveActionConfiguration(action: CreationActions): Promise<Actions.Action> {
    const existingConf = this._getStoredActionConfiguration(action);
    if (existingConf) {
      return existingConf;
    }

    const actionAlias = GetSendActionAlias(action);
    const { body: conf } = await actionsApi.getActionByAlias(actionAlias);

    store.commit(`${CREATION_NAMESPACE}/${SET_SEND_ACTION_CONF}`, { key: action, conf });

    return conf;
  }

  /**
   * Clears the action's configuration
   *
   * @param action - the action whose configuration to clear
   */
  clearActionConfiguration(action: CreationActions): void {
    store.commit(`${CREATION_NAMESPACE}/${CLEAR_SEND_ACTION_CONF}`, { key: action });
  }

  /**
   * Retrieves the existing form or fetches it from the api
   * WARNING: you must have previously loaded the action configuration
   *
   * @param action - the action whose form to retrieve
   */
  async retrieveActionForm(action: CreationActions): Promise<Form.Form> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const existingForm = store.getters[`${CREATION_NAMESPACE}/getActionForm`](action) as Form.Form;
    if (existingForm) {
      return existingForm;
    }

    const actionConf = this._getStoredActionConfiguration(action);
    if (!actionConf) {
      throw new Error(
        `Could not retrieve action form for ${action}: did not retrieve action configuration beforehand`
      );
    }
    if (!actionConf.form?.href) {
      throw new Error(`Could not retrieve action form for ${action}: there is no form`);
    }

    const { body: form } = await (Vue.http.get(actionConf.form.href) as Response<Form.Form>);

    store.commit(`${CREATION_NAMESPACE}/${SET_SEND_ACTION_FORM}`, { key: action, form });

    return form;
  }

  /**
   * Clears the action's form
   *
   * @param action - the action whose form to clear
   */
  clearActionForm(action: CreationActions): void {
    store.commit(`${CREATION_NAMESPACE}/${CLEAR_SEND_ACTION_FORM}`, { key: action });
  }

  /**
   * Clears cache
   */
  private clearCache(): void {
    store.commit(`${CREATION_NAMESPACE}/${RESET_STATE}`);
  }
}
