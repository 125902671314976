import Vue from 'vue';

import i18n from '../../../core/i18n';
import services from '../../../core/services';
import { ResolvedResponse } from '../../../core/types/http';
import attributesApi, { AttributeValues, Form } from '../../common/api/attributes';
import { AttributeTypes, DisplayMode } from '../../common/constants';
import { iterateAttrGroup, iterateForm } from '../../common/helpers/formsHelper';
import templatesApi from '../api/templates';
import {
  LogsDefinitions,
  PagedCollection,
  TemplateFormats,
  TemplateTypes,
  TemplateValues,
} from '../api/types';
import { PosterScopes } from '../constants';
import { iterateFilterGroups } from '../helpers/filterHelpers';
import { getPosterService } from '.';
import { IAlertsService } from './../../../platform/services/types';
import { CartPoster, FormatsCopiesMap } from './../types/poster';
import { SignageValues } from './../types/templates';
import { TypeData } from './templatesService';
import { FamiliesAndTypes, ITemplatesService, IValuesService } from './types';

export type LoadedBindings = {
  type: string;
  values: {
    [alias: string]: unknown;
  };
  taintedValues: {
    [alias: string]: unknown;
  };
  formats: TemplateValues.FormatsInfos;
} | null;

export class ValuesService implements IValuesService {
  searchResultsCpt = 0;

  /**
   * Load bindings for a template and inputs values in parameters.
   * @param templateId Template Id
   * @param typeData - poster type data
   * @param poster - cart poster
   * @param resetValues - True to overwrite tainted values (reset), false otherwise
   * @returns the loaded bindings or an empty object
   */
  async loadBindings(
    templateId: string,
    typeData: TypeData,
    poster: CartPoster,
    resetValues = false
  ): Promise<LoadedBindings | Record<string, never>> {
    const bodyParameters = new Map<string, unknown>();

    // Formats
    const allFormats = typeData.formatGroups.flatMap((group) => group.formats);
    const formatsInfoMap: TemplateValues.FormatsInfos = {};
    Object.entries(poster.formatsCopiesMap).forEach(([formatId, info]) => {
      const format = allFormats.find((f) => f.itemId === formatId);
      if (format) {
        formatsInfoMap[format.alias] = {
          copies: info.copies,
          visible: info.visible ?? true,
          disabled: info.disabled ?? false,
        };
      }
    });
    bodyParameters.set('formats', formatsInfoMap);

    // Attribute values
    iterateForm(typeData?.form, (attribute) => {
      this.addBodyParameter(bodyParameters, attribute, attribute.alias, poster.values);
    });

    let result: ResolvedResponse<TemplateValues.BindingValues> | null;
    if (bodyParameters.size > 0) {
      result = await templatesApi.getTemplateValues(templateId, bodyParameters);
    } else {
      return {};
    }

    const bindings: LoadedBindings = {
      type: result.body.type,
      values: {},
      taintedValues: {},
      formats: result.body.formats,
    };

    result.body.attributes.forEach((value) => {
      const attributeAlias = value.attributeAlias;
      if (value.attributeAlias == null) {
        return;
      }

      // Update all values if reset option or not tainted values only
      if (resetValues || !poster.taintedValues[attributeAlias]) {
        let processedValue = value.value;
        if (value.type === 'Date') {
          processedValue = Vue.filter('isoDate')(processedValue);
        }

        bindings.values[attributeAlias] = processedValue;
        bindings.taintedValues[attributeAlias] = false;
      } else {
        // No change on value (keep tainted value)
        bindings.values[attributeAlias] = poster.values[attributeAlias];
        bindings.taintedValues[attributeAlias] = true;
      }
    });

    return bindings;
  }

  /**
   * @inheritdoc
   */
  async search<TItem extends TemplateValues.SearchResultItem>(
    templateId: string,
    searchSourceId: string,
    parameters: {
      attrGroups: Form.AttributeGroup[] | null;
      values: SignageValues;
    }[],
    paging?: { index: number; size: number },
    sorts?: [string, string][]
  ): Promise<PagedCollection<TItem>> {
    if (!templateId || !searchSourceId) {
      return {
        items: [],
        pageIndex: 0,
        pageSize: 0,
        totalNumberOfItems: 0,
        totalNumberOfPages: 0,
        prevPageIndex: 0,
        nextPageIndex: 0,
      };
    }

    // Build search parameters
    const bodyParameters = new Map<string, unknown>();

    parameters.forEach((params) => {
      iterateAttrGroup(params.attrGroups, (attribute) => {
        this.addBodyParameter(bodyParameters, attribute, attribute.alias, params.values);
      });
    });

    try {
      const res = await templatesApi.searchTemplateValues(
        templateId,
        searchSourceId,
        bodyParameters,
        paging,
        sorts
      );

      const results = res.body as PagedCollection<TItem>;

      results.items.forEach((i) => {
        i._id = ++this.searchResultsCpt;
      });

      return results;
    } catch (error) {
      if (error == null || (error as any).status !== 404) {
        services
          .getService<IAlertsService>('alerts')
          ?.alertError(i18n.t('poster.search.error') as string);
      }

      return {
        items: [],
        pageIndex: 0,
        pageSize: 0,
        totalNumberOfItems: 0,
        totalNumberOfPages: 0,
        prevPageIndex: 0,
        nextPageIndex: 0,
      };
    }
  }

  /**
   * @inheritdoc
   */
  async importSearch(
    templateId: string,
    searchSourceId: string,
    parameters: {
      attrGroups: Form.AttributeGroup[] | null;
      values: SignageValues;
    }[],
    paging?: { index: number; size: number },
    sorts?: [string, string][]
  ): Promise<TemplateValues.ImportSearchResult> {
    const res = await this.search<TemplateValues.ImportSearchResultItemRaw>(
      templateId,
      searchSourceId,
      parameters,
      paging,
      sorts
    );

    let familiesAndTypes: FamiliesAndTypes | null = null;
    let allTypes: Record<string, TemplateTypes.Type> | null = null;

    // Resolve preselected type and formats from search results
    for (const item of res.items) {
      const importItem = item as TemplateValues.ImportSearchResultItem;

      if (typeof item.piivo_type === 'string') {
        familiesAndTypes ??= await getPosterService<ITemplatesService>(
          'templates'
        ).loadFamiliesAndTypes(PosterScopes.BatchCreation);
        allTypes ??= [...familiesAndTypes?.rootTypes.values()].reduce((acc, rootTypes) => {
          rootTypes.forEach((rootType) => {
            acc[rootType.alias] = rootType;
            rootType.children?.forEach((childType) => {
              acc[childType.alias] = childType;
            });
          });

          return acc;
        }, {} as Record<string, TemplateTypes.Type>);

        const type = allTypes[item.piivo_type];

        // If the type is not found or doesn't match the scope, remove it and its formats
        if (!type) {
          importItem.piivo_type = null;
          importItem.piivo_formats = null;
          continue;
        }

        importItem.piivo_type = type;

        if (
          item.piivo_formats &&
          typeof item.piivo_formats === 'object' &&
          !Array.isArray(item.piivo_formats)
        ) {
          const typeFormatGroups = await getPosterService<ITemplatesService>(
            'templates'
          ).loadTypeFormatGroups(type.itemId, PosterScopes.BatchCreation);
          const allFormats = typeFormatGroups.reduce((acc, formatGroup) => {
            formatGroup.formats?.forEach((format) => {
              acc[format.alias] = format;
            });
            return acc;
          }, {} as Record<string, TemplateFormats.Format>);

          const resolvedFormats: FormatsCopiesMap = {};
          for (const [formatAlias, formatInfo] of Object.entries(item.piivo_formats)) {
            const format = allFormats[formatAlias];
            // Only add formats found in the type's actual formats list, including scope
            if (format) {
              resolvedFormats[format.itemId] = {
                label: format.label,
                copies: formatInfo.copies,
                disabled: false,
                visible: true,
                permission: format.permission,
              };
            }
          }

          importItem.piivo_formats = resolvedFormats;
        }
      }
    }

    return res as TemplateValues.ImportSearchResult;
  }

  /**
   * Get possible option values for an attribute
   *
   * @param attributeTrigger - the attribute to retrieve the options for
   * @param parameterValues - Object with parameters values (key = property name)
   * @param allAttrGroups - all the attribute groups whose attributes correspond to 'parameterValues'
   * @param context - additional context to send with the request
   * @param templateIdTrigger - fallback: the template the attribute belongs to. Used to retrieve the provider
   * @param addBodyParams - Callback to add body parameters to the request
   */
  async getPossibleValues<OptionValue extends AttributeValues.AttributeOptionValue>(
    attributeTrigger: Form.Attribute,
    context: object | null,
    templateIdTrigger: string | null,
    addBodyParams: (bodyParameters: Map<string, unknown>) => void
  ): Promise<OptionValue[]> {
    // If the link has no source, its options are defined statically
    if (!attributeTrigger.source) {
      return (attributeTrigger.parameters?.links ?? []) as unknown as OptionValue[];
    }

    // Otherwise the options can be retrieved via api

    // Init body parameters and use callback to add values
    const bodyParameters = new Map<string, unknown>();
    addBodyParams(bodyParameters);
    // After all values, add additional context
    bodyParameters.set('context', context);

    if (attributeTrigger.source.provider) {
      // Current api: get values directly from attribute source
      return (
        await attributesApi.getPossibleValues<OptionValue>(attributeTrigger.itemId, bodyParameters)
      ).body;
    } else if (templateIdTrigger) {
      // Backwards compat & legacy attributes: the search id is available but the provider
      // is defined on the template. Search via template api
      return (
        await templatesApi.getPossibleValues<OptionValue>(
          templateIdTrigger,
          attributeTrigger.source.sourceId,
          bodyParameters
        )
      ).body;
    }

    throw new Error('Attribute does not have source information for values');
  }

  /**
   * Adds all the attribute values to the body parameters
   *
   * @param bodyParameters - the body parameters to modify
   * @param attributeValues - Object with parameters values (key = property name)
   * @param allAttrGroups - all the attribute groups whose attributes correspond to 'attributeValues'
   */
  addAttributeValuesBodyParameters(
    bodyParameters: Map<string, unknown>,
    attributeValues: SignageValues,
    allAttrGroups: Form.AttributeGroup[] | null
  ): void {
    // Add all attribute values to the body
    iterateAttrGroup(allAttrGroups, (attribute) => {
      this.addBodyParameter(bodyParameters, attribute, attribute.alias, attributeValues);
    });
  }

  /**
   * Adds all the filter values to the body parameters
   *
   * @param bodyParameters - the body parameters to modify
   * @param filterValues - Object with parameters values (key = property name)
   * @param filterGroups - all the filter groups whose filters correspond to 'filterValues'
   */
  addFilterValuesBodyParameters(
    bodyParameters: Map<string, unknown>,
    filterValues: { [filterAlias: string]: { value: AttributeValues.AttributeValue } },
    filterGroups: LogsDefinitions.FiltersGroup[] | null
  ): void {
    // Convert the filters state to filters values map
    const valuesMap = Object.fromEntries(
      Object.entries(filterValues).map(([alias, state]) => [alias, state.value])
    );

    // Add all filter values to the body
    iterateFilterGroups(filterGroups, (filter) => {
      if (filter.attribute) {
        this.addBodyParameter(bodyParameters, filter.attribute, filter.alias, valuesMap);
      }
    });
  }

  /**
   * Adds the attribute's value to the parameters map.
   *
   * @param bodyParameters - The map with parameters (key: alias, value : parameter value)
   * @param attribute - the attribute whose value to add
   * @param valueAlias - the alias of the value
   * @param values - Object with current values
   */
  addBodyParameter(
    bodyParameters: Map<string, unknown>,
    attribute: Form.Attribute,
    valueAlias: string,
    values: SignageValues
  ): void {
    if (!values || !valueAlias || !Object.hasOwnProperty.call(values, valueAlias)) {
      return;
    }

    // Get alias value
    let parameterValue = values[valueAlias];

    if (parameterValue && Array.isArray(parameterValue) && parameterValue.length > 0) {
      // For combo and tree display modes, use special value syntax
      // Else leave value untouched
      if (
        attribute.type === AttributeTypes.LINKS &&
        [DisplayMode.Combo, DisplayMode.Tree].includes(attribute.options.displayMode!)
      ) {
        const mappedValues = (parameterValue as any[])
          .map((el) => {
            if (el && typeof el === 'object' && Object.hasOwnProperty.call(el, 'alias')) {
              return el.alias as string;
            } else if (el && typeof el === 'string') {
              return el;
            }
            return el as string;
          })
          .filter(Boolean);

        parameterValue = mappedValues.length > 1 ? JSON.stringify(mappedValues) : mappedValues[0];
      }
    } else if (
      typeof parameterValue === 'object' &&
      parameterValue != null &&
      !Array.isArray(parameterValue)
    ) {
      parameterValue = (parameterValue as any).alias;
    }

    // Verify parameter value and add it in parameters map
    if (
      parameterValue != null &&
      (typeof parameterValue !== 'string' || parameterValue.length > 0)
    ) {
      bodyParameters.set(valueAlias, parameterValue);
    }
  }

  /**
   * @inheritdoc
   */
  valuesObjectsToDirectMap(
    values: Record<string, AttributeValues.ValueObject>
  ): Record<string, AttributeValues.AttributeValue> {
    return Object.values(values).reduce((valuesMap, attrValue) => {
      if (attrValue.attributeAlias) {
        valuesMap[attrValue.attributeAlias] = attrValue.value;
      }
      return valuesMap;
    }, {} as Record<string, AttributeValues.AttributeValue>);
  }
}
