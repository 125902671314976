export default {
  // Dashboard
  DASHBOARD: 'signages_dashboard',

  // Quick signage creation
  QUICK: 'signages_quick',
  QUICK_SAVE: 'signages_quick_save',

  // Signages listing
  LOGS: 'signages_logs',
  LOGS_FILTER: 'signages_logs_filter',
  LOGS_RESET_FILTERS: 'signages_logs_resetFilters',
  LOGS_EDIT: 'signages_logs_edit',
  LOGS_ARCHIVE: 'signages_logs_archive',
  LOGS_DELETE: 'signages_logs_delete',
  CREATE_FROM: 'signages_create_from',
  PRINT_AUTO: 'signages_printAuto',
  DOWNLOAD_PDF: 'signages_downloadPDF',
};
