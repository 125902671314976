import './components/table/editors';
import './static/style/app.scss';

import PiivoPosterEngine from 'piivo-poster-engine/src/lib';
import Vue from 'vue';

import { getExtensionPoint, registerExtensionPoint } from '../../core/extensionPoints';
import { components } from './components';
import { dashboardWidgets } from './components/dashboard';
import { PosterTableRenderers } from './components/table/renderers';
import { getViewer, registerViewer } from './components/viewers';
import { POSTER_APP_NAME, POSTER_SERVICES_NAMESPACE } from './constants';
import PosterRoutes from './routes';
import { addPosterService } from './services';
import { ActionDefinitionsService } from './services/actionDefinitionService';
import { PosterAttributesService } from './services/attributesService';
import { CartStateService } from './services/cartState';
import { DownloadPostersService } from './services/downloadPosters';
import { GlobalAttributesService } from './services/globalAttributes';
import { ImportModesService } from './services/importModesService';
import { PDFDownloaderService } from './services/pdfDownloaderService';
import { PosterDashboardsService } from './services/posterDashboardsService';
import { PosterResourcesService } from './services/posterResourcesService';
import { PosterUploadsService } from './services/posterUploadsService';
import { PrinterService } from './services/printerService';
import { SendPostersService } from './services/sendPosters';
import { SignagesService } from './services/signagesService';
import { SignagesSynchronization } from './services/signagesSynchronization';
import { TemplatesService } from './services/templatesService';
import { ValuesService } from './services/valuesService';
import {
  ActionDefinitionsModule,
  NAMESPACE as ACTION_DEFINITIONS_NAMESPACE,
} from './store/modules/actionDefinitions';
import PosterModule, { NAMESPACE as POSTER_NAMESPACE } from './store/modules/poster';
import { formatters } from './utils/formatters';

const moduleName = 'poster';

/**
 * Function to install poster app module
 * */
function install(platformExtension, useCache) {
  Vue.use(PiivoPosterEngine, {
    proxy:
      process.env.NODE_ENV === 'development'
        ? (url) => `/_proxyTo?url=${encodeURIComponent(url)}`
        : null,
  });
  if (!useCache) {
    getExtensionPoint('poster.engine').clearCachedData();
  }

  registerExtensionPoint(moduleName, {
    tableRenderers: PosterTableRenderers,
    viewers: {
      registerViewer,
      getViewer,
    },
  });

  const formattingManager = getExtensionPoint('poster.engine').formattingManager;
  for (const formatter of formatters) {
    formattingManager.registerFormatter(formatter.name, formatter.func);
  }

  for (const component of components) {
    Vue.component(component.name, component);
  }

  platformExtension.router.addRoutes(PosterRoutes);

  platformExtension.registerStoreModule(POSTER_NAMESPACE, PosterModule);
  platformExtension.registerStoreModule(ACTION_DEFINITIONS_NAMESPACE, ActionDefinitionsModule);

  const templatesService = new TemplatesService(platformExtension.store);
  addPosterService('signagesSync', new SignagesSynchronization());
  addPosterService('importModes', new ImportModesService());
  addPosterService('PDFDownloader', new PDFDownloaderService());
  addPosterService('signages', new SignagesService(platformExtension.store));
  addPosterService('templates', templatesService);
  addPosterService('values', new ValuesService());
  addPosterService('downloadPosters', new DownloadPostersService());
  addPosterService('posterAttributes', new PosterAttributesService());
  addPosterService('actionDefinitions', new ActionDefinitionsService(platformExtension.store));
  addPosterService('cartState', new CartStateService());
  addPosterService('dashboards', new PosterDashboardsService());
  addPosterService('posterResourcesService', new PosterResourcesService());
  addPosterService('printer', new PrinterService());
  addPosterService('sendPosters', new SendPostersService());
  addPosterService(
    'globalAttributes',
    new GlobalAttributesService(platformExtension.store, platformExtension.router)
  );
  addPosterService('uploads', new PosterUploadsService());

  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .componentsManager.$on(
      'error',
      templatesService.onTemplateComponentError.bind(templatesService)
    );
  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .fontsManager.$on('error', templatesService.onTemplateFontError.bind(templatesService));

  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .lesserEval.$on('error', (errorCode, data) => {
      console.error(`poster engine lesserEval error: ${errorCode}`, data);
    });

  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .lesserEval.$on('warn', (data) => {
      console.warn('poster engine lesserEval warning: ', data);
    });

  dashboardWidgets.forEach((def) => {
    platformExtension.dashboardWidgetsManager.registerWidget(def.name, def.widget);
  });
}

getExtensionPoint('platform')
  .services.getService('moduleManager')
  .registerModule({
    name: moduleName,
    install,
    application: {
      alias: POSTER_APP_NAME,
      servicesNamespace: POSTER_SERVICES_NAMESPACE,
    },
  });
