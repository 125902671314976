import i18n from '../../../core/i18n';
import services from '../../../core/services';
import {
  IAlertsService,
  IAnalyticsService,
  INotificationsService,
} from '../../../platform/services/types';
import { AttributeValues } from '../../common/api/attributes';
import { IActionsService } from '../../common/services/types';
import logsApi, { Convert } from '../api/logs';
import sendPosterNotification from '../components/creation/SendPosterNotification.vue';
import { CreationActions, PosterCreationStates } from '../constants';
import { getPosterService } from '.';
import {
  ConversionPromiseFns,
  ConvertDetails,
  IDownloadPostersService,
  IPdfConverter,
  IPDFDownloaderService,
  ISignagesSynchronization,
} from './types';

/**
 * The creation progress proportion for the send+download notification
 */
const CREATION_PROGRESSION_PROPORTION = 0.5;

/**
 * The download progress proportion for the send+download notification
 */
const DOWNLOAD_PROGRESSION_PROPORTION = 1 - CREATION_PROGRESSION_PROPORTION;

export class DownloadPostersService implements IDownloadPostersService {
  /**
   * @inheritdoc
   */
  public async convertToPdf(
    idsToConvert: string[],
    parameters: { values: AttributeValues.ValueObject[] | null; [x: string]: any },
    trackEvent: string,
    opts?: { promiseFns?: ConversionPromiseFns }
  ): Promise<string> {
    let converter;

    try {
      converter = await this.createConverter(idsToConvert.length, trackEvent, false, opts);

      const createConversionRes = await logsApi.convertToPdfAsync(idsToConvert, parameters);

      converter.followPdfConversion(createConversionRes.actionId);
      return createConversionRes.actionId;
    } catch (err) {
      converter?.onStartError(err);
      throw err;
    }
  }

  /**
   * @inheritdoc
   */
  public async createConverter(
    postersCount: number,
    trackEvent: string,
    includesCreation = false,
    opts?: { promiseFns?: ConversionPromiseFns }
  ): Promise<PdfConverter> {
    const converter = new PdfConverter(postersCount, trackEvent, includesCreation, opts);
    await converter.init();
    return converter;
  }
}

class PdfConverter implements IPdfConverter {
  private notificationId = '-1';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private resolve: (value: string[] | PromiseLike<string[]>) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  private reject: (reason: any) => void = () => {};
  /**
   * The action id of the followed conversion
   */
  private actionId: string | null = null;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly postersCount: number,
    private readonly trackEvent: string,
    private readonly includesCreation: boolean,
    opts?: { promiseFns?: ConversionPromiseFns }
  ) {
    if (opts?.promiseFns) {
      this.resolve = opts.promiseFns.resolve;
      this.reject = opts.promiseFns.reject;
    }
  }

  /**
   * Attaches the converter callbacks to the action polling
   *
   * @param actionId - the action to poll
   * @param converter - the converter to attach its callbacks with
   */
  private pollAction(actionId: string, converter: PdfConverter) {
    return services.getService<IActionsService>('actions')!.pollAction<ConvertDetails>(actionId, {
      onStatusEnqueued: converter.onConversionEnqueued.bind(converter),
      onStatusFinished: converter.onConversionFinished.bind(converter),
      onStatusInError: converter.onConversionError.bind(converter),
    });
  }

  /**
   * @inheritdoc
   */
  public followPdfConversion(actionId: string): void {
    if (this.actionId) {
      throw new Error(`Already following conversion ${this.actionId}`);
    }

    try {
      this.actionId = actionId;
      void this.pollAction(this.actionId, this);
    } catch (err) {
      this.onStartError(err);
      throw err;
    }
  }

  /**
   * @inheritdoc
   */
  public setCreationProgress(creationProgress: number): void {
    this.updateNotification({
      progress: creationProgress * CREATION_PROGRESSION_PROPORTION,
      isCreating: creationProgress < 100,
    });
  }

  /**
   * Creates the download notification
   */
  public async init(): Promise<void> {
    this.notificationId =
      (await services
        .getService<INotificationsService>('notifications')
        ?.createNotification(sendPosterNotification, {
          state: PosterCreationStates.IN_PROGRESS,
          action: CreationActions.DOWNLOAD,
          count: this.postersCount,
          pdfUrls: [],
          progress: -1,
          isCreating: this.includesCreation,
        })) ?? '-1';

    services.getService<INotificationsService>('notifications').setShowNotifications(true);
  }

  /**
   * Updates the download notification
   *
   * @param data - notification data
   */
  private updateNotification(data: object): void {
    if (this.notificationId !== '-1') {
      services
        .getService<INotificationsService>('notifications')
        ?.updateNotification(this.notificationId, data);
    }
  }

  /**
   * Callback for the enqueued conversion's details
   *
   * @param details - conversion details
   */
  private onConversionEnqueued(details: ConvertDetails): void {
    const progress = this.includesCreation
      ? CREATION_PROGRESSION_PROPORTION * 100 + DOWNLOAD_PROGRESSION_PROPORTION * details.progress
      : details.progress;
    const isCreating = this.includesCreation && progress / 100 < CREATION_PROGRESSION_PROPORTION;

    this.updateNotification({
      progress,
      isCreating,
    });
  }

  /**
   * Callback for the finished conversion's details
   *
   * @param details - conversion details
   */
  private async onConversionFinished(details: ConvertDetails): Promise<void> {
    // Start sync delay so we can get posters with their updated generation status in demands page
    getPosterService<ISignagesSynchronization>('signagesSync').startSynchronizationDelay();

    if (
      details.result.status === Convert.ResultStatus.OK ||
      details.result.status === Convert.ResultStatus.WARNING
    ) {
      try {
        if (details.result.status === Convert.ResultStatus.WARNING) {
          services
            .getService<IAlertsService>('alerts')
            ?.alertWarning(i18n.t('poster.convert.pdf.warning') as string);
        }

        const pdfUrls = details.result.urls;

        this.updateNotification({
          state: PosterCreationStates.DONE_SUCCESS,
          pdfUrls,
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        services
          .getService<IAnalyticsService>('analytics')
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          ?.trackEvent(this.trackEvent, null as any, {
            signagesNumber: this.postersCount,
            PDFsNumber: pdfUrls != null ? pdfUrls.length : 0,
          });

        await getPosterService<IPDFDownloaderService>('PDFDownloader').downloadAll(pdfUrls);

        this.resolve(pdfUrls);
      } catch (err) {
        services
          .getService<IAlertsService>('alerts')
          ?.alertError(i18n.t('poster.creation.creation_tasks.alerts.failed.download') as string);

        this.reject(err);
      }
    } else {
      this.updateNotification({
        state: PosterCreationStates.DONE_ERROR,
      });

      this.reject(details);
    }
  }

  /**
   * Callback for the errored conversion's details
   *
   * @param details - conversion details
   */
  private onConversionError(details: ConvertDetails): void {
    // Start sync delay so we can get posters with their updated generation status in demands page
    getPosterService<ISignagesSynchronization>('signagesSync').startSynchronizationDelay();

    services
      .getService<IAlertsService>('alerts')
      ?.alertError(i18n.t('poster.convert.pdf.error') as string);

    this.updateNotification({
      state: PosterCreationStates.DONE_ERROR,
    });

    this.reject(details);
  }

  /**
   * Callback for a conversion start error
   *
   * @param err - error from creating the conversion, or other error
   */
  public onStartError(err: any): void {
    // Alert error
    if (Object.hasOwnProperty.call(err, 'status') && err.status === 408) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.convert.pdf.server_unresponsive') as string);
    } else {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.convert.pdf.error') as string);
    }

    this.updateNotification({
      state: PosterCreationStates.DONE_ERROR,
    });

    this.reject(err);
  }
}
