import Vue from 'vue';

import i18n from '../../../core/i18n';
import services from '../../../core/services';
import { UploadDetails } from '../api/upload';
import { UploadStates } from '../constants/upload';
import { IAlertsService, INotificationsService } from './../../../platform/services/types';
import { IUploadManager } from './uploadsService';

/**
 * Manages the upload and its notification state
 */
export class UploadManager implements IUploadManager {
  /**
   * The files to upload
   */
  files: File[];
  /**
   * Values for the upload task
   */
  values: Record<string, unknown>;
  /**
   * Options to customise the handling of the upload
   */
  opts: {
    notificationComponent: Vue;
    extras: object;
  };

  /**
   * The ID of the notification associated to this upload
   */
  notificationId: string;
  /**
   * The count of files that were uploaded successfully
   */
  successFileCount: number;
  /**
   * The names of the files that could not be uploaded
   */
  errorFileNames: string[];

  /**
   *
   * @param files - the files to upload
   * @param values - values for the upload task
   * @param extras - extra data for the upload
   */
  constructor(
    files: File[],
    values: Record<string, unknown>,
    opts: {
      notificationComponent: Vue;
      extras: object;
    }
  ) {
    this.files = files;
    this.values = values;
    this.opts = opts;
    this.notificationId = '-1';
    this.successFileCount = 0;
    this.errorFileNames = [];
  }

  /**
   * @inheritdoc
   */
  public async init(): Promise<void> {
    this.notificationId =
      (await services
        .getService<INotificationsService>('notifications')
        .createNotification(this.opts.notificationComponent, {
          state: UploadStates.CREATING,
          values: this.values,
          totalFileCount: this.files.length,
          successFileCount: 0,
          errorFileNames: [],
          uploadedFileIds: [],
          extras: this.opts.extras,
        })) ?? -1;
  }

  /**
   * @inheritdoc
   */
  public onUploadStarted(): void {
    services
      .getService<INotificationsService>('notifications')
      .updateNotification(this.notificationId, {
        state: UploadStates.UPLOADING_FILES,
      });
  }

  /**
   * @inheritdoc
   */
  public onFileUploadSuccess(): void {
    this.successFileCount++;
    services
      .getService<INotificationsService>('notifications')
      .updateNotification(this.notificationId, {
        successFileCount: this.successFileCount,
      });
  }

  /**
   * @inheritdoc
   *
   * @param {string} fileName - the total number of files that succeeded in uploading
   */
  public onFileUploadError(fileName: string): void {
    this.errorFileNames.push(fileName);
    services
      .getService<INotificationsService>('notifications')
      .updateNotification(this.notificationId, {
        errorFileNames: this.errorFileNames,
      });
  }

  /**
   * @inheritdoc
   */
  public onProcessingStarted(): void {
    services
      .getService<INotificationsService>('notifications')
      .updateNotification(this.notificationId, {
        state: UploadStates.PROCESSING,
      });
  }

  /**
   * @inheritdoc
   */
  public onUploadSuccess(uploadDetails: UploadDetails): void {
    const { uploadedFileIds } = uploadDetails;

    services
      .getService<INotificationsService>('notifications')
      .updateNotification(this.notificationId, {
        state: UploadStates.DONE_SUCCESS,
        uploadedFileIds,
      });

    services
      .getService<IAlertsService>('alerts')
      .alertSuccess(i18n.t('common.upload.alert.success') as string);
  }

  /**
   * @inheritdoc
   */
  public onUploadError(): void {
    if (this.notificationId !== '-1') {
      services
        .getService<INotificationsService>('notifications')
        .updateNotification(this.notificationId, {
          state: UploadStates.DONE_ERROR,
        });
    }

    services
      .getService<IAlertsService>('alerts')
      .alertError(i18n.t('common.upload.alert.error') as string);
  }
}
