import Vue from 'vue';

import i18n from '../../../core/i18n';
import services from '../../../core/services';
import AttributesApi, { AttributeValues, Form } from '../../common/api/attributes';
import { IAlertsService } from './../../../platform/services/types';
import { IPosterAttributesService } from './types';

export class PosterAttributesService implements IPosterAttributesService {
  /**
   * Imports local files to the attribute's external storage
   *
   * @param attribute - the attribute onto which we are uploading the files
   * @param tempFiles - array of objects containing the file blobs to upload
   * @param onFileUploadedCb - callback for when a file is finished uploading.
   * Receives the original temp file, and the uploaded file
   * @param provideCancelCb - callback that will provide the function to abort
   * the import. The abort function is called with a specific id
   * of an item whose upload to cancel
   */
  async importExternalFiles<F extends { id: string; file: File }>(
    attribute: Form.Attribute,
    tempFiles: F[],
    onFileUploadedCb: (tempFile: F, f: any) => void,
    provideCancelCb: (cancelCb: (tempId: string) => void) => void
  ): Promise<AttributeValues.ExternalLinkValueItem[]> {
    services
      .getService<IAlertsService>('alerts')
      ?.alertInfo(i18n.t('poster.attribute_external_link.upload.alerts.started') as string);

    let totalFileCount = tempFiles.length;
    let nFailed = 0;
    const importedFiles: AttributeValues.ExternalLinkValueItem[] = [];
    const processedTempIds: string[] = [];

    const abortIds: string[] = [];
    const requestMap: { [tempId: string]: { abort: () => void } } = {};
    provideCancelCb((tempId?: string) => {
      if (tempId && requestMap[tempId]) {
        // Abort the requested request if in progress
        requestMap[tempId].abort();
        delete requestMap[tempId];

        totalFileCount--;
      } else if (tempId && !processedTempIds.includes(tempId) && !abortIds.includes(tempId)) {
        // Else we did not yet start the upload. Track the id as aborted
        abortIds.push(tempId);

        totalFileCount--;
      }
    });

    try {
      for (const tempFile of tempFiles) {
        if (abortIds.includes(tempFile.id)) {
          // If the file was marked as aborted, just skip it
          processedTempIds.push(tempFile.id);
          continue;
        }

        try {
          const res = await AttributesApi.importAttributeValue(
            attribute.itemId,
            tempFile.file,
            (request) => {
              requestMap[tempFile.id] = request as { abort: () => void };
            }
          );

          // After the request is done, untrack it
          delete requestMap[tempFile.id];

          const importValue = (res.body.value as AttributeValues.ExternalLinkValueItem[])[0];
          if (importValue) {
            onFileUploadedCb(tempFile, importValue);
            importedFiles.push(importValue);
          } else {
            throw new Error('Did not receive value for imported file');
          }
        } catch (err) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          if (!(Vue.http as any).isCancel(err)) {
            nFailed++;
          }
        }
        processedTempIds.push(tempFile.id);
      }

      if (totalFileCount) {
        // We could've canceled all the uploads, so only alert
        // If we actually did at least 1
        services.getService<IAlertsService>('alerts').alertSuccess(
          i18n.t('poster.attribute_external_link.upload.alerts.success', {
            countTotal: totalFileCount,
            countSuccess: totalFileCount - nFailed,
            countError: nFailed,
          }) as string
        );
      }
    } catch (err) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertInfo(i18n.t('poster.attribute_external_link.upload.alerts.failed') as string);
    }

    return importedFiles;
  }
}
