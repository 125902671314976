<template>
  <div v-if="form" class="pos-global-attributes-form pos-global-attributes-form__root">
    <div class="pos-global-attributes-form__content">
      <pui-attribute-group-panel
        v-for="group in form.attributeGroups"
        :key="group.itemId"
        :group="group"
      >
        <template v-if="group.attributes">
          <pui-attribute-panel
            v-for="attribute in group.attributes"
            :ref="`formAttribute_${attribute.alias}`"
            :key="attribute.itemId"
            :attribute="attribute"
            :value="globalValues[attribute.alias]"
            :loadOptionsFunction="getPossibleValues"
            :importExternalFiles="importExternalFiles"
            @valueInput="onValueInput(attribute, $event)"
            @valueChanged="onValueChanged(attribute, $event)"
            @searchRequested="openAttributeSearch($event, true)"
          />
        </template>
      </pui-attribute-group-panel>
    </div>

    <div class="pos-global-attributes-form__separator"></div>
  </div>
</template>

<script>
import { AttributeTypes } from '../../../common/constants';
import { updateOn } from '../../../common/helpers/formsHelper';
import { getPosterService } from '../../services';
export default {
  name: 'PosGlobalAttributesForm',
  computed: {
    /**
     * @returns {object} the poster form
     */
    form() {
      return getPosterService('globalAttributes')?.getForm();
    },
    /**
     * @returns global values
     */
    globalValues() {
      return getPosterService('globalAttributes').getGlobalValues();
    },
  },
  methods: {
    /**
     * Updates attribute value
     *
     * @param {object} attribute - the attribute whose value changed
     * @param {Object} newValue - New attribute value
     */
    onValueInput(attribute, value) {
      getPosterService('globalAttributes').setGlobalValue(attribute, value);
    },
    /**
     * Updates attribute value and checks form
     *
     * @param {object} attribute - the attribute whose value changed
     * @param {Object} newValue - New attribute value
     */
    onValueChanged(attribute, value) {
      this.onValueInput(attribute, value);
      this.startUpdateOn(attribute);
    },
    /**
     * Launches the "updateOn" mechanism for the attribute
     *
     * @param {object} attribute - the attribute whose updateOn to trigger
     */
    startUpdateOn(attribute) {
      const actionCb = (cbAttr) => {
        if (cbAttr.type === AttributeTypes.LINKS) {
          this.$nextTick(() => {
            const attributePanel = this.$refs[`formAttribute_${cbAttr.alias}`];
            if (attributePanel != null && attributePanel[0] != null) {
              attributePanel[0].loadOptions().catch(() => null);
            }
          });
        }
      };

      updateOn(this.currentForm, attribute, actionCb);
    },
    /**
     * Imports external files to the attribute's storage
     *
     * @param {object} attribute - the attribute onto which we are uploading the files
     * @param {object[]} tempFiles - array of objects containing the file blobs to upload
     * @param {(tempFile, importedFile) => void} onFileUploadedCb - callback for when a file is finished uploading.
     * Receives the original temp file, and the uploaded file
     * @param {(cancelCb: (tempId: string) => void) => void} provideAbortCb - callback
     * that proves the cancel function
     */
    async importExternalFiles(attribute, tempFiles, onFileUploadedCb, provideAbortCb) {
      return await getPosterService('posterAttributes').importExternalFiles(
        attribute,
        tempFiles,
        onFileUploadedCb,
        provideAbortCb
      );
    },
    /**
     * Open search panel for the attribute in parameter.
     * @param {Object} options - Search options
     * @param {Object} options.attribute - The search attribute
     * @param {Number} options.maxSelection - Max selection for the search
     * @param {string} options.maxSelectionMessageKey - Max selection exceeded message key
     * @param {Boolean} isInput - Flag  that defines if attribute is an input
     */
    async openAttributeSearch(options, isInput = false) {
      // No template for search
      throw new Error('not implemented yet');
    },
    /**
     * @param {object} attribute - the attribute whose options to retrieve
     * @param {object} parameters - search parameters
     * @param {object} parameters.context - context to send with the search
     * @returns {Promise<object[]>} options for the attribute
     */
    getPossibleValues(attribute, { context }) {
      return getPosterService('values').getPossibleValues(
        attribute,
        context,
        null,
        (bodyParameters) =>
          getPosterService('values').addAttributeValuesBodyParameters(
            bodyParameters,
            this.globalValues,
            this.form.attributeGroups || []
          )
      );
    },
  },
};
</script>
