import services from '../../../core/services';
import { Response } from '../../../core/types/http';
import { IApplicationsService, INotificationsService } from '../../../platform/services/types';
import { ActionDetails } from '../api/actions';
import uploadApi, { UploadDetails } from '../api/upload';
import { IActionsService, IUploadsService, UploadConfiguration } from './types';
import { UploadManager } from './uploadManager';

export interface IUploadManager {
  /**
   * Initializes the instance
   */
  init(): Promise<void>;

  /**
   * Handles the start of the upload process
   */
  onUploadStarted: () => void;
  /**
   * Handles the success of a file upload
   */
  onFileUploadSuccess: () => void;
  /**
   * Handles the error of a file upload
   */
  onFileUploadError: (fileName: string) => void;
  /**
   * Handles the start of processing of the uploaded files
   */
  onProcessingStarted: () => void;
  /**
   * Handles the success of the upload & processing
   */
  onUploadSuccess: (uploadDetails: UploadDetails) => void;
  /**
   * Handles total failure of upload/processing
   */
  onUploadError: () => void;
}

export abstract class UploadsService implements IUploadsService {
  /**
   * @param appName - the app name the service will use for uploads configuration
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly appName: string) {}

  /**
   * @inheritdoc
   */
  public getUploadsConfiguration(): UploadConfiguration[] {
    return (
      services
        .getService<IApplicationsService>('applications')
        .getAppParameter<UploadConfiguration[]>(this.appName, 'uploads', true) ?? []
    );
  }

  /**
   * @inheritdoc
   */
  public async uploadFiles(
    files: File[],
    values: Record<string, unknown>,
    taskName: string,
    opts: {
      notificationComponent: Vue;
      extras: object;
    }
  ): Promise<void> {
    let uploadManager: IUploadManager | null = null;
    try {
      if (!files.length) {
        throw new Error('Did not receive any files to upload');
      }

      uploadManager = new UploadManager(files, values, opts);
      await uploadManager.init();

      const createUploadRes = await uploadApi.createUpload();
      const uploadId = createUploadRes.body;

      uploadManager.onUploadStarted();

      // Upload files 1 by 1 to avoid monopolizing xhr threads
      for (const file of files) {
        try {
          await uploadApi.sendFileToUpload(uploadId, file);
          uploadManager.onFileUploadSuccess();
        } catch (err) {
          uploadManager.onFileUploadError(file.name);
        }
      }

      // Start processing
      uploadManager.onProcessingStarted();
      const uploadExectionResult = await uploadApi.executeUpload(uploadId, values, taskName);

      // Start polling asynchronously
      void services
        .getService<IActionsService>('actions')
        .pollAction(uploadExectionResult.body.taskId, {
          onStatusFinished: uploadManager.onUploadSuccess.bind(uploadManager),
          onStatusInError: uploadManager.onUploadError.bind(uploadManager),
        });

      services.getService<INotificationsService>('notifications').setShowNotifications(true);
    } catch (err) {
      uploadManager?.onUploadError();
    }
  }

  /**
   * @param uploadId - the id of the upload to retrieve the details of
   * @returns the api response or error
   */
  private async getUploadDetails(uploadId: string): Response<UploadDetails> {
    return uploadApi.getUploadById(uploadId);
  }
}
