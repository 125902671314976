import Vue from 'vue';

import { Response } from './../../../core/types/http';
import { PosterForm, TemplateContent, TemplateFormats, TemplateTypes } from './types';

const TEMPLATES_TYPES_API_URL = '/api/signages/templates/types';

export default {
  /**
   * Get type details
   *
   * @param typeId - The type identifier (Guid)
   */
  async getType(typeId: string): Response<TemplateTypes.Type> {
    return Vue.http.get(`${TEMPLATES_TYPES_API_URL}/${typeId}`) as Response<TemplateTypes.Type>;
  },

  /**
   * Get formats list for the type Id in parameter.
   *
   * @param typeId - The type identifier (Guid)
   */
  async getFormatGroupsByTypeId(typeId: string): Response<TemplateFormats.FormatGroups[]> {
    return Vue.http.get(`${TEMPLATES_TYPES_API_URL}/${typeId}/formats`) as Response<
      TemplateFormats.FormatGroups[]
    >;
  },

  /**
   * Get form for the type Id in parameter.
   *
   * @param typeId - Type Id (Guid)
   */
  async getForm(typeId: string): Response<PosterForm> {
    return Vue.http.get(`${TEMPLATES_TYPES_API_URL}/${typeId}/form`) as Response<PosterForm>;
  },

  /**
   * Get a template content by type and format Id.
   *
   * @param typeId - Type Id (Guid)
   * @param formatId - Format Id (Guid)
   */
  async getTemplateByTypeAndFormat(
    typeId: string,
    formatId: string
  ): Response<TemplateContent.Content> {
    return Vue.http.get(
      `${TEMPLATES_TYPES_API_URL}/content?type=${typeId}&format=${formatId}`
    ) as Response<TemplateContent.Content>;
  },
};
